body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
   list-style: none;
   padding: 0;
   margin: 0;
}



.header-m {
   font-weight: 700;
   font-size: 30px;
}
.header-s {
   font-weight: 700;
   font-size: 20px;
}
.padding-s {
   padding: 1rem;
}
.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.underline {
   text-decoration: underline;
}

.no-select {
   user-select: none;
}
.hidden {
   display: none;
}

.cardHeader-s {
   color: var(--text-0-o-50);
   font-size: 14px;
   font-weight: 700;
   letter-spacing: 1.12px;
   text-transform: uppercase;
}
