.contentC {
   width: min(90%, 600px);
   margin: auto;
   padding: 1rem;
   background-color: var(--background-0);
   border-radius: 20px;
   > header {
      margin-bottom: 1rem;
   }
   .dupeC{
      display: block;
      color: red;
      width: 100%;
      text-align: center;
      margin-top: 10px;
   }
   .bodyC {
      .infoC {
         display: flex;
         gap: 1rem;
         margin-bottom: 1rem;
      }
      .formRow {
         display: flex;
         gap: 1rem;
         align-items: center;
         margin-bottom: 0.8rem;
      }
      .unitsC {
         padding: 0.8rem;
         background-color: var(--background-1);
         border-radius: 10px;
         .unitsHeader {
            display: flex;
            gap: 0.8rem;
            align-items: center;
         }
         .units {
            display: flex;
            flex-direction: column;
            .unit {
               padding-top: 0.6rem;
               padding-bottom: 0.6rem;
               display: flex;
               gap: 0.8rem;
               align-items: center;
               &:not(:last-child) {
                  border-bottom: 1px solid var(--text-0-o-10);
               }
            }
         }
      }
      .controlsC {
         display: flex;
         gap: 12px;
         flex-direction: column;
         align-items: center;
         margin-top: 1rem;
         .successC {
            color: green;
            text-align: center;
         }
         .errorC {
            color: red;
            text-align: center;
         }
      }
   }
}
